import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import ReactRotatingText from 'react-rotating-text';
import { Link, useNavigate } from 'react-router-dom';
import './home.css';
function genRandomTree(N = 500, reverse = false) {
  return {
    nodes: [...Array(N).keys()].map(i => ({ id: i })),
    links: [...Array(N).keys()]
      .filter(id => id)
      .map(id => ({
        [reverse ? 'target' : 'source']: id,
        [reverse ? 'source' : 'target']: Math.round(Math.random() * (id - 1))
      }))
  };
}
function Home() {
  const [count,setCount] = useState(0);
  const randomNode = 210;
  const navigate = useNavigate();

  const data = useMemo(() => {
    const gdata = genRandomTree(500);
    return gdata;
  }, []);
  function increaseCount(){
    setCount(count=>count + 1);
  }

  const drawNode = (node, ctx, globalScale) => {
    const size = node.id === randomNode ? 10 : 5; // Larger size for highlighted node
    
    ctx.beginPath();
    ctx.arc(node.x, node.y, size, 0, 2 * Math.PI, false);
    ctx.fillStyle = node.id === randomNode ? 'black' : '#ff6978';
    ctx.fill();
    ctx.font = `${8}px Sans-Serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'black'; // Text color
  };

  const handleNodeClick = node => {
    if (node.id === randomNode){
      navigate('/demo', { replace: true });
    }
  };
  
  const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
  const [value, setValue] = React.useState(pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="App">
      <div id="graph"></div>
      <ForceGraph2D className="force"
        backgroundColor='#FFFFFF'
        showNavInfo={false}
        graphData={data}
        enableNodeDrag={false}
        nodeOpacity={0.2}
        linkOpacity={0.2}
        id="force"
        autoPauseRedraw={false}
        nodeCanvasObject={drawNode}
        onNodeClick={handleNodeClick}
      />
      <header className="App-header">
        <img className="App-logo" alt="Octance" src="/logo.svg" />
      </header>

      <p  className="attraction">Managing Value should be</p>
      {count < 7 && <p className="attraction"><span className="attractionTag"><ReactRotatingText onTypingEnd={() =>increaseCount()} items={['DEVELOPER FIRST', 'EVENT DRIVEN','DOMAIN AGNOSTIC','REALTIME','LESS STRESSFUL', 'DELIGHTFUL TO YOU', 'AI ENABLED']} /></span></p>}
      {count === 7 && <p className="attraction"><span className="attractionTag">AI ENABLED</span> after all...</p>}
      {count === 7 && <p className="attractionLastLine">Having <span className="tag">moments</span> of inspiration? Share them with us at <a className='mailto' href="mailto:[info@octance.ai]">info@octance.ai</a></p>}

      <Paper sx={{ boxShadow: 'none', backgroundColor: 'transparent',color: "transparent", position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels={true} value={value} onChange={handleChange} sx={{ width: "100%" }}>
          <BottomNavigationAction
            component={Link}
            to="/readme.md"
            label="README.MD"
            value="README.MD"
            icon={<QuestionMarkOutlinedIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="https://dev.octance.ai/docs"
            label="Developer Portal"
            value="Developer Portal"
            icon={<CodeSharpIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="https://438p30okwnv.typeform.com/to/P8ecD8kV"
            label="Contact"
            value="Contact"
            icon={<ConnectWithoutContactIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="https://docs.google.com/presentation/d/e/2PACX-1vS5sJm6up6E6FpNHGI3Cx0_uAivEqEWpLFjSkTU_K18UMaKtDCxp6QCxzvj_1FYNQUEuWdRy8pPiF0A/pub?start=false&loop=false&delayms=3000"
            label="Pitch Deck"
            value="Pitch Deck"
            icon={<OndemandVideoIcon />}
          />
        </BottomNavigation>
      </Paper>
    </div>
  );
}

export default Home;
